/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { PersonnelTable } from '../../../_metronic/partials/widgets/tables/PersonnelTable'
import { PersonnelCount } from '../../../_metronic/partials/widgets/_new/cards/PersonnelCount'
import { PersonnelCountCard } from '../../../_metronic/partials/widgets/_new/cards/PersonnelCountCard'
import { PersonnelCountGateCard } from '../../../_metronic/partials/widgets/_new/cards/PersonnelCountGateCard'
import { PersonnelStatusCard } from '../../../_metronic/partials/widgets/_new/lists/PersonnelStatusCard'

const PersonnelPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <PersonnelCount
          className='h-md-100 mb-5 mb-xl-10'
          description='Total Personnel'
          color='#DBF0FF'
          // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>

      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' /> */}
        <PersonnelStatusCard 
        title='Personnel Status'
        className='h-lg-100' />
      </div>

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <PersonnelCountGateCard className='h-md-100 mb-5 mb-xl-10' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <PersonnelCountCard className='h-md-100 mb-5 mb-xl-10' />
      </div>
      {/* end::Col */}
    </div>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <PersonnelTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const PersonnelWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <PersonnelPage />
    </>
  )
}

export {PersonnelWrapper}
