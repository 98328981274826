import { StringDecoder } from "string_decoder"

export interface IPersonnelDetails {
  id:string
  avatar: string
  first_name: string
  last_name: string
  ic_no: string
  passport_no: string
  nationality_id : string
  gender: string
  religion: string
  blood_type : string
  contact_no: string
  cidb_date: string
  passport_date: string
  working_permit_date: string
  company_letter: string
  company_status:number
  clq_status: number
  address:string
  company_name_diff: string
  emergency_first_name: string
  emergency_last_name: string
  relationship: string
  emergency_address: string
  emergency_phone_no: string
  emergency_home_phone_no: string
  company_id: string;
  company: ICompanyDetails;
  status : string;
  created_by: string;
}
export interface IProfileInfos {
  id:string
  username: string
  email: string
  email_verified_at: string 
  status: string
  profile: IProfileData
}

export interface IProfileData {
  id:string
  avatar: string
  name: string
  address: string 
  phone_no: string
  email: string
}

export interface ICompanyDetails {
  id:string
  avatar: string
  name: string
  address: string 
  phone_no: string
  email: string
}

export interface ICountryDetails {
  id:string
  code: string
  name: string
}

export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'Keenthemes',
  contactPhone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const companyDetailsInitValues: ICompanyDetails = {
  id: '',
  avatar: '',
  name: '',
  address: '',
  phone_no: '',
  email: '',
};

export const personnelDetailsInitValues: IPersonnelDetails = {
  id:'',
  avatar: '',
  company: companyDetailsInitValues, // Use the initialized company object directly
  company_id:'',
  first_name: '',
  last_name: '',
  ic_no: '',
  passport_no: '',
  nationality_id : '',
  gender: '',
  religion: '',
  blood_type: '',
  contact_no: '',
  cidb_date: '',
  passport_date: '',
  working_permit_date: '',
  company_status: 0,
  clq_status: 0,
  company_letter: '',
  address: '',
  company_name_diff: '',
  emergency_first_name: '',
  emergency_last_name: '',
  relationship: '',
  emergency_address: '',
  emergency_phone_no: '',
  emergency_home_phone_no: '',
  status: '',
  created_by : 'asdasd'
};



export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
