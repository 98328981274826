import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/auth/me`
export const LOGIN_URL = `${API_URL}/api/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/auth/change-password`
export const COMPANY_URL = `${API_URL}/api/companies`
export const PROFILE_URL = `${API_URL}/api/users`
export const PERSONNEL_URL = `${API_URL}/api/personnels`
export const COUNTRY_URL = `${API_URL}/api/countries`


// Server should return AuthModel
export function login(login: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    login,
    password,
  })
}



export function countryDetail(token:string) {
  return axios.get(COUNTRY_URL, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function companyDetail(token:string) {
  return axios.get(COMPANY_URL, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function personnelDetail(token:string) {
  return axios.get(PERSONNEL_URL, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function personnelInfo(token:string, id:any) {
  return axios.get(PERSONNEL_URL + '/' + id, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function companyInfo(token:string, id:any) {
  return axios.get(COMPANY_URL + '/' + id, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function countryInfo(token:string, id:any) {
  return axios.get(COMPANY_URL + '/' + id, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}

export function profileInfo(token:string, id:any) {
  
  return axios.get(PROFILE_URL + '/' + id, {
    headers: {
      'Authorization': `Bearer ${token}` // Ensure token is correct and valid
    }
  });
}



export async function updateCompany(formData: FormData, id: any) {
  try {
    const response = await axios.post(`${COMPANY_URL}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important for file uploads
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error in updating company:', error);
    throw error; // Throw the error to be handled by the calling function
  }
}


export async function updatePersonnel(formData: FormData, id: any) {
  try {
    const response = await axios.post(`${PERSONNEL_URL}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important for file uploads
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error in updating personnel:', error);
    throw error; // Throw the error to be handled by the calling function
  }
}


// Server should return AuthModel
export async function registerCompany(formData: FormData) {

  try {
    const response = await axios.post(COMPANY_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important for file uploads
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error in registering company:', error);
    throw error; // Throw the error to be handled by the calling function
  }
}



// Server should return AuthModel
export async function registerPersonnel(formData: FormData) {

  try {
    const response = await axios.post(PERSONNEL_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important for file uploads
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error in registering company:', error);
    throw error; // Throw the error to be handled by the calling function
  }
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  localStorage.setItem('authToken', token);

  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
