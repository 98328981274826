/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG} from '../../../../helpers'

type Props = {
  className: string,
  title: String
}

const rows: Array<{description: string, value: number}> = [
  {description: 'Verified' , value: 10},
  {description: 'Pending', value :12},
  {description: 'Deactivate' , value :15},
]

const PersonnelStatusCard = ({className, title}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>{title}</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <div className='fw-semibold fs-6 me-2'>
              {row.description}
            </div>
            <div className='fw-semibold fs-6 me-2'>
              {row.value}
            </div>

          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {PersonnelStatusCard}
