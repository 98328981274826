import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ICompanyDetails, companyDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import { toApiUrl } from '../../../_metronic/helpers'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { companyInfo, updateCompany } from '../../modules/auth/core/_requests'

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  phone_no: Yup.string().required('Contact No is required'),
  email: Yup.string().required('Email is required'),
})

const EditCompanyWrapper: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [data, setData] = useState<ICompanyDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const { id } = useParams<{ id: string }>(); // Get the id from the URL

  const [selectedImage, setSelectedImage] = useState<File | null>(null); // Store the selected image file
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null); // Store the image preview URL


  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const token = localStorage.getItem('authToken');

      if (!token) {
        console.error('No authorization token found');
        return;
      }

      try {
        const { data } = await companyInfo(token, id);  // Call your API with the token
        console.log('data :', data)
        setData(data);
        formik.setValues({
          id: data.id,
          name: data.name,
          address: data.address,
          phone_no: data.phone_no,
          email: data.email,
          avatar: data.avatar,
        });

      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyInfo();
  }, [id]);

  const formik = useFormik<ICompanyDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setStatus(''); // Reset status on each submit

      try {

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('address', values.address);
        formData.append('phone_no', values.phone_no);
        formData.append('email', values.email);
        formData.append('_method', 'PUT'); 

        console.log(selectedImage);
        if (selectedImage) {
          formData.append('avatar', selectedImage); // Append the file only if selected
        }
        formData.forEach((value, key) => {
          console.log('beroo : ',key, value);
        });

        await updateCompany(formData, id); // Call the API

        setStatus('success');
        setTimeout(() => {
          navigate('/company');
        }, 1000);
      } catch (error) {
        console.error('Error registering company:', error);
        const typedError = error as any;

        if (typedError.response && typedError.response.data && typedError.response.data.message) {
          setStatus(typedError.response.data.message);
        } else if (typedError.message) {
          setStatus(typedError.message);
        } else {
          setStatus('An unknown error occurred. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    },
  });

  // Handle image input change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedImage(file); // Set the selected file
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file)); // Create preview URL for the selected image
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Update Company Registration</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {status === 'success' ? (
              <div className="mb-lg-15 alert alert-success">
                <div className="alert-text font-weight-bold">Company Information Updated</div>
              </div>
            ) : status !== '' ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            ) : ''}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url(${toApiUrl('/media/avatars/blank.png')})` }}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: imagePreviewUrl 
                        ? `url(${imagePreviewUrl})`  // Use the preview URL if available
                        : data?.avatar 
                        ? `url(${toApiUrl(data.avatar)})`  // Fallback to data.avatar if no preview is selected
                        : `url('https://www.talentxchange.my/_assets/images/default_logo.png')`,  // Default image
                    }}
                  ></div>
                </div>
              </div>
            </div>

            {/* Image Upload */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Upload Photo</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  className='form-control form-control-lg form-control-solid'
                  accept="image/*"
                  onChange={handleImageChange} // Handle image change
                />
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company Name'
                  {...formik.getFieldProps('name')} // <-- Bind formik values
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Address</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company Address'
                  {...formik.getFieldProps('address')} // <-- Bind formik values
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Contact No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company Contact No'
                  {...formik.getFieldProps('phone_no')} // <-- Bind formik values
                />
                {formik.touched.phone_no && formik.errors.phone_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone_no}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Email Address</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company Email Address'
                  {...formik.getFieldProps('email')} // <-- Bind formik values
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { EditCompanyWrapper }

