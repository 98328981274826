import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IPersonnelDetails, ICompanyDetails, ICountryDetails, personnelDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import { toAbsoluteUrl, toApiUrl } from '../../../_metronic/helpers'
import { personnelInfo, updatePersonnel, companyDetail, countryDetail } from '../../modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth'
import { useParams } from 'react-router-dom';


const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  ic_no: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  contactPhone: Yup.string().required('Contact phone is required'),
  companySite: Yup.string().required('Company site is required'),
  nationality_id: Yup.string().required('Nationality_id is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
  currency: Yup.string().required('Currency is required'),
})

const EditPersonnelWrapper: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const { currentUser } = useAuth()

  const [companyData, setCompanyData] = useState<ICompanyDetails[]>([]);
  const [countryData, setCountryData] = useState<ICountryDetails[]>([]);

  const [data, setData] = useState<IPersonnelDetails>(initialValues)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [selectedImage, setSelectedImage] = useState<File | null>(null); // Store the selected image file
  const [selectedPdf, setSelectedPdf] = useState<File | null>(null); // Store the selected image file
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null); // Store the image preview URL
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState<string | null>(null); // Store the image preview URL

  const { id } = useParams<{ id: string }>(); // Get the id from the URL

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      // Get the token from localStorage
      const token = localStorage.getItem('authToken');

      if (!token) {
        console.error('No authorization token found');
        return;
      }

      try {
        const { data: companyResponse } = await companyDetail(token);  // Call your API with the token
        const { data: countryResponse } = await countryDetail(token);  // Call your API with the token
        const { data } = await personnelInfo(token, id);  // Call your API with the token

        setCompanyData(companyResponse);
        setCountryData(countryResponse);
        setData(data);

        formik.setValues({
          id: data.id,  // Include the 'id' field
          avatar: data.avatar,
          company_id: data.company_id,
          first_name: data.first_name,
          last_name: data.last_name,
          ic_no: data.ic_no,
          passport_no: data.passport_no,
          nationality_id: data.nationality_id,
          gender: data.gender,
          religion: data.religion,
          blood_type: data.blood_type,
          contact_no: data.contact_no,
          cidb_date: data.cidb_date,
          passport_date: data.passport_date,
          working_permit_date: data.working_permit_date,
          clq_status: data.clq_status,
          company_status: data.company_status,
          company_letter: data.company_letter,
          address: data.address,
          company_name_diff: data.company_name_diff,
          emergency_first_name: data.emergency_first_name,
          emergency_last_name: data.emergency_last_name,
          relationship: data.relationship,
          emergency_address: data.emergency_address,
          emergency_phone_no: data.emergency_phone_no,
          emergency_home_phone_no: data.emergency_home_phone_no,
          status: data.status,
          created_by: data.created_by,
          company: data.company  // Include the 'company' field
        });

      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [id]);


  // Formik setup
  const formik = useFormik<IPersonnelDetails>({
    initialValues,
    // validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setStatus(''); // Reset status on each submit

      try {
        // Create FormData object for multipart form data
        const formData = new FormData();

        // Append each field from the IPersonnelDetails to the FormData object
        formData.append('avatar', values.avatar);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('ic_no', values.ic_no);
        formData.append('passport_no', values.passport_no);
        formData.append('nationality_id', values.nationality_id);
        formData.append('gender', values.gender);
        formData.append('religion', values.religion);
        formData.append('blood_type', values.blood_type);
        formData.append('contact_no', values.contact_no);
        formData.append('cidb_date', values.cidb_date);
        formData.append('passport_date', values.passport_date);
        formData.append('working_permit_date', values.working_permit_date);
        formData.append('company_status', String(values.company_status)); // Convert boolean to string
        formData.append('clq_status', String(values.clq_status)); // Convert boolean to string
        formData.append('address', values.address);
        formData.append('company_name_diff', values.company_name_diff);
        formData.append('emergency_first_name', values.emergency_first_name);
        formData.append('emergency_last_name', values.emergency_last_name);
        formData.append('relationship', values.relationship);
        formData.append('emergency_address', values.emergency_address);
        formData.append('emergency_phone_no', values.emergency_phone_no);
        formData.append('emergency_home_phone_no', values.emergency_home_phone_no);
        formData.append('company_id', values.company_id);
        formData.append('status', 'Review Applicant');
        formData.append('created_by', String(currentUser?.id) ?? '');
        formData.append('_method', 'PUT');

        if (selectedImage) {
          formData.append('avatar', selectedImage); // Append the file only if selected
        }

        if (selectedPdf) {
          formData.append('company_letter', selectedPdf); // Append the file only if selected
        }

        formData.forEach((value, key) => {
          console.log(key, value);
        });

        const response = await updatePersonnel(formData, id); // Call the API with FormData
        console.log('API Response:', response.data);

        // Show success message and redirect
        setStatus('success');
        setTimeout(() => {
          navigate('/personnel');
        }, 1500);
      } catch (error) {
        console.error('Error registering personnel:', error);

        const typedError = error as any; // Type assertion as 'any'

        // Check if error has a response object (likely an API error)
        if (typedError.response && typedError.response.data && typedError.response.data.message) {
          setStatus(typedError.response.data.message); // Use API error message
        } else if (typedError.message) {
          setStatus(typedError.message); // Use the general error message
        } else {
          setStatus('An unknown error occurred. Please try again.'); // Fallback message
        }
      } finally {
        setLoading(false); // Stop the loading state
      }
    },
  });

  // Handle image input change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedImage(file); // Set the selected file
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file)); // Create preview URL for the selected image
    }
  };

  const handlePdfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedPdf(file); // Set the selected file
    if (file) {
      setPdfPreviewUrl(URL.createObjectURL(file)); // Create preview URL for the selected image
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Update Personnel Registration</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='card-title mb-10'>
              <h3 className='fw-bolder m-0'>Part A - Personnel Details</h3>
            </div>
            {status === 'success' ? (
              <div className="mb-lg-15 alert alert-success">
                <div className="alert-text font-weight-bold">Company Information Updated</div>
              </div>
            ) : status !== '' ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            ) : ''}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: imagePreviewUrl
                        ? `url(${imagePreviewUrl})`  // Use the preview URL if available
                        : data?.avatar
                          ? `url(${toApiUrl(data.avatar)})`  // Fallback to data.avatar if no preview is selected
                          : `url('https://www.talentxchange.my/_assets/images/default_logo.png')`,  // Default image
                    }}
                  ></div>
                </div>
              </div>
            </div>

            {/* Image Upload */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Upload Photo</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  className='form-control form-control-lg form-control-solid'
                  accept="image/*"
                  onChange={handleImageChange} // Handle image change
                />
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('company_id')}
                >
                  <option value=''>Select a Company...</option>
                  {companyData.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                  {/* i got a companyData can you help me to loop the data in option */}
                </select>
                {formik.touched.company_id && formik.errors.company_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Personnel Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('first_name')}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('last_name')}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Identification Card or Passport Number</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Identification Card'
                      {...formik.getFieldProps('ic_no')}
                    />
                    {formik.touched.ic_no && formik.errors.ic_no && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.ic_no}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Passport No'
                      {...formik.getFieldProps('passport_no')}
                    />
                    {formik.touched.passport_no && formik.errors.passport_no && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.passport_no}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Nationality</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('nationality_id')}
                >
                  <option value=''>Select a Nationality...</option>
                  {countryData.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {formik.touched.nationality_id && formik.errors.nationality_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nationality_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Gender</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Gender'
                  {...formik.getFieldProps('gender')}
                />
                {formik.touched.gender && formik.errors.gender && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gender}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Religion</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Religion'
                  {...formik.getFieldProps('religion')}
                />
                {formik.touched.religion && formik.errors.religion && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.religion}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Blood Type</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Blood Type'
                  {...formik.getFieldProps('blood_type')}
                />
                {formik.touched.blood_type && formik.errors.blood_type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.blood_type}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...formik.getFieldProps('contact_no')}
                />
                {formik.touched.contact_no && formik.errors.contact_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.contact_no}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>CIDB Validatity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='CIDB Validatity Date'
                  {...formik.getFieldProps('cidb_date')}
                />
                {formik.touched.cidb_date && formik.errors.cidb_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cidb_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Passport Validity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Passport Validatity Date'
                  {...formik.getFieldProps('passport_date')}
                />
                {formik.touched.passport_date && formik.errors.passport_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passport_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Working Permit Validatity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Working Permit Validatity Date'
                  {...formik.getFieldProps('working_permit_date')}
                />
                {formik.touched.working_permit_date && formik.errors.working_permit_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.working_permit_date}</div>
                  </div>
                )}
              </div>
            </div>
            {formik.values.company_status}

            <div className='bg-light rounded p-5 ms-n5'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Company Name in working permit different as Employer's Details</label>
                <div className='col-lg-8 fv-row'>
                  <div className='d-flex align-items-center mt-3'>
                    <label className='form-check form-check-inline form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        name='company_status'
                        type='radio'
                        value='1' // Value for 'Non CLQ'
                        checked={formik.values.company_status == 1} // Prefill the value for 'Non CLQ'
                        onChange={(e) => formik.setFieldValue('company_status', Number(e.target.value))}

                      />
                      <span className='fw-bold ps-2 fs-6'>Yes</span>
                    </label>



                    <label className='form-check form-check-inline form-check-solid'>
                      <input
                        className='form-check-input'
                        name='company_status'
                        type='radio'
                        value='0' // Value for 'Non CLQ'
                        checked={formik.values.company_status == 0} // Prefill the value for 'Non CLQ'
                        onChange={(e) => formik.setFieldValue('company_status', Number(e.target.value))}
                      />
                      <span className='fw-bold ps-2 fs-6'>No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Undertaking Letter</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    accept="pdf/*"
                    onChange={handlePdfChange} // Handle image change
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Name'
                    {...formik.getFieldProps('company_name_diff')}
                  />
                  {formik.touched.company_name_diff && formik.errors.company_name_diff && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company_name_diff}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='pt-5'>
              <div className='bg-light rounded p-5 ms-n5'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Current Residential Address</label>

                  <div className='col-lg-8 fv-row'>
                    <div className='d-flex align-items-center mt-3'>
                      <label className='form-check form-check-inline form-check-solid me-5'>
                        <input
                          className='form-check-input'
                          name='clq_status'
                          type='radio'
                          value='1' // Value for 'CLQ'
                          checked={formik.values.clq_status == 1} // Prefill the value for 'CLQ'
                          onChange={(e) => formik.setFieldValue('clq_status', Number(e.target.value))}
                        />
                        <span className='fw-bold ps-2 fs-6'>CLQ</span>
                      </label>

                      <label className='form-check form-check-inline form-check-solid'>
                        <input
                          className='form-check-input'
                          name='clq_status'
                          type='radio'
                          value='0' // Value for 'Non CLQ'
                          checked={formik.values.clq_status == 0} // Prefill the value for 'Non CLQ'
                          onChange={(e) => formik.setFieldValue('clq_status', Number(e.target.value))}
                        />

                        <span className='fw-bold ps-2 fs-6'>Non CLQ</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Residential Address</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Residential Address'
                      {...formik.getFieldProps('address')}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-body border-top p-9'>
            <div className='card-title mb-10'>
              <h3 className='fw-bolder m-0 '>Part B - Emergency Details (Spouse / Next of Kin)</h3>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('emergency_first_name')}
                    />
                    {formik.touched.emergency_first_name && formik.errors.emergency_first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.emergency_first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('emergency_last_name')}
                    />
                    {formik.touched.emergency_last_name && formik.errors.emergency_last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.emergency_last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Relationship</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Relationship'
                  {...formik.getFieldProps('relationship')}
                />
                {formik.touched.relationship && formik.errors.relationship && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.relationship}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Residential Address</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Residential Address'
                  {...formik.getFieldProps('emergency_address')}
                />
                {formik.touched.emergency_address && formik.errors.emergency_address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Mobile No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Mobile No'
                  {...formik.getFieldProps('emergency_phone_no')}
                />
                {formik.touched.emergency_phone_no && formik.errors.emergency_phone_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_phone_no}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Home Phone No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Home Phone No'
                  {...formik.getFieldProps('emergency_home_phone_no')}
                />
                {formik.touched.emergency_home_phone_no && formik.errors.emergency_home_phone_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_home_phone_no}</div>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { EditPersonnelWrapper }
