/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { KTSVG, toApiUrl } from '../../../helpers'
import { IPersonnelDetails } from '../../../../app/modules/accounts/components/settings/SettingsModel';
import { personnelDetail } from '../../../../app/modules/auth/core/_requests'

type Props = {
  className: string
}

const PersonnelTable: React.FC<Props> = ({ className }) => {

  const [personnelData, setPersonnelData] = useState<IPersonnelDetails[]>([]);

  useEffect(() => {
    const fetchPersonnelDetails = async () => {
      // Get the token from localStorage
      const token = localStorage.getItem('authToken');

      if (!token) {
        console.error('No authorization token found');
        return;
      }

      try {
        const { data } = await personnelDetail(token);  // Call your API with the token
        setPersonnelData(data);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchPersonnelDetails();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Personnel List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 Personnel</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <Link to='/personnel/create'>
            <button
              type='button'
              className='btn btn-sm btn-primary'
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Create Personnel
            </button>
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Personnel Name</th>
                <th className='min-w-140px'>Company Name / Email</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {personnelData ? (
                personnelData.map((personnel: IPersonnelDetails, index: number) => (
                  <tr>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={personnel.avatar
                            ? toApiUrl(personnel.avatar)  // Use the avatar from the company object 
                            : 'https://www.talentxchange.my/_assets/images/default_logo.png' // Fallback to a default image
                          } alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {personnel.first_name}  {personnel.last_name}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {personnel.company.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {personnel.company.name}
                      </div>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {personnel.company.email}
                      </span>
                    </td>
                    <td>
                      {personnel.status == 'Successful' ?
                        <span className="badge bg-success">{personnel.status} </span>
                        : personnel.status == 'Pending' ?
                          <span className="badge bg-warning">{personnel.status} </span>
                          :
                          <span className="badge bg-danger">{personnel.status} </span>
                      }


                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link to={`/personnel/view/${personnel.id}`}>
                          <div
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </div>
                        </Link>
                        <Link to={`/personnel/edit/${personnel.id}`}>
                          <div
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                          </div>
                        </Link>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              )
                : ''
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { PersonnelTable }
