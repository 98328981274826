import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ICompanyDetails, companyDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import { toAbsoluteUrl, toApiUrl } from '../../../_metronic/helpers'
import { registerCompany } from '../../modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom';

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  phone_no: Yup.string().required('Contact No is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const CreateCompanyWrapper: React.FC = () => {

  const navigate = useNavigate(); // Initialize the navigate function

  const [data, setData] = useState<ICompanyDetails>(initialValues)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [selectedImage, setSelectedImage] = useState<File | null>(null); // Store the selected image file
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null); // Store the image preview URL

  // Formik setup
  const formik = useFormik<ICompanyDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setStatus(''); // Reset status on each submit

      try {
        // Create FormData object for multipart form data
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('address', values.address);
        formData.append('phone_no', values.phone_no);
        formData.append('email', values.email);

        if (selectedImage) {
          console.log('selectedImageselectedImageselectedImage :', typeof (selectedImage));
          formData.append('avatar', selectedImage); // Append the file only if selected
        }
        formData.forEach((value, key) => {
          console.log(key, value);
        });

        const response = await registerCompany(formData); // Call the API with FormData
        console.log('API Response:', response.data);

        // Show success message and redirect
        setStatus('success');
        setTimeout(() => {
          navigate('/company');
        }, 1000);
      } catch (error) {
        console.error('Error registering company:', error);

        const typedError = error as any; // Type assertion as 'any'

        // Check if error has a response object (likely an API error)
        if (typedError.response && typedError.response.data && typedError.response.data.message) {
          setStatus(typedError.response.data.message); // Use API error message
        } else if (typedError.message) {
          setStatus(typedError.message); // Use the general error message
        } else {
          setStatus('An unknown error occurred. Please try again.'); // Fallback message
        }
      } finally {
        setLoading(false); // Stop the loading state
      }
    },
  });

  // Handle image input change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedImage(file); // Set the selected file
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file)); // Create preview URL for the selected image
    }
  };

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Company Registration</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {status === 'success' ? (
                <div className="mb-lg-15 alert alert-success">
                  <div className="alert-text font-weight-bold">Company Information Updated</div>
                </div>
              ) : status !== '' ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              ) : ''}


              {/* Avatar Image Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${imagePreviewUrl || 'https://www.talentxchange.my/_assets/images/default_logo.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                      }}
                    ></div>
                  </div>
                </div>
              </div>


              {/* Image Upload */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Upload Photo</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    accept="image/*"
                    onChange={handleImageChange} // Handle image change
                  />
                </div>
              </div>

              {/* Company Name */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Name</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Company Address */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Address</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Contact Number */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Contact No</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Contact No'
                    {...formik.getFieldProps('phone_no')}
                  />
                  {formik.touched.phone_no && formik.errors.phone_no && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone_no}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Email Address</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Email Address'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { CreateCompanyWrapper }
