import React, {useState} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
// import {IPersonnelDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { IPersonnelDetails, personnelDetailsInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  ic_no: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  contactPhone: Yup.string().required('Contact phone is required'),
  companySite: Yup.string().required('Company site is required'),
  nationality: Yup.string().required('Nationality is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
  currency: Yup.string().required('Currency is required'),
})

const ViewPersonnelWrapper: React.FC = () => {
  const [data, setData] = useState<IPersonnelDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IPersonnelDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IPersonnelDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        // values.communications.email = data.communications.email
        // values.communications.phone = data.communications.phone
        // values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Personnel Registration</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='card-title mb-10'>
              <h3 className='fw-bolder m-0'>Part A - Personnel Details</h3>
            </div> 
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                  ></div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Company Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('company_id')}
                >
                  <option value=''>Select a Company...</option>
                  <option value='AF'>Alagapas Sdn. Bhd.</option>
                  <option value='AX'>Shayville Sdn. Bhd.</option>
                  <option value='AL'>Bero Sdn. Bhd.</option>
                  <option value='DZ'>Nazsoftech Sdn. Bhd.</option>
                  <option value='AS'>Bawal Power Sdn. Bhd.</option>
                </select>
                {formik.touched.company_id && formik.errors.company_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Personnel Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('first_name')}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('last_name')}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Identification Card or Passport Number</label>

              <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Identification Card'
                    {...formik.getFieldProps('ic_no')}
                  />
                  {formik.touched.ic_no && formik.errors.ic_no && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.ic_no}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Passport No'
                        {...formik.getFieldProps('passport_no')}
                      />
                      {formik.touched.passport_no && formik.errors.passport_no && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.passport_no}</div>
                        </div>
                      )}
                    </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Nationality</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('nationality')}
                >
                  <option value=''>Select a Nationality...</option>
                  <option value='AF'>Afghanistan</option>
                  <option value='AX'>Aland Islands</option>
                  <option value='AL'>Albania</option>
                  <option value='DZ'>Algeria</option>
                  <option value='AS'>American Samoa</option>
                </select>
                {formik.touched.nationality_id && formik.errors.nationality_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nationality_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Gender</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Gender'
                  {...formik.getFieldProps('gender')}
                />
                {formik.touched.gender && formik.errors.gender && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gender}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Religion</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Religion'
                  {...formik.getFieldProps('religion')}
                />
                {formik.touched.religion && formik.errors.religion && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.religion}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Blood Type</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Blood Type'
                  {...formik.getFieldProps('blood_type')}
                />
                {formik.touched.blood_type && formik.errors.blood_type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.blood_type}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...formik.getFieldProps('contact_no')}
                />
                {formik.touched.contact_no && formik.errors.contact_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.contact_no}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>CIDB Validatity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='CIDB Validatity Date'
                  {...formik.getFieldProps('cidb_date')}
                />
                {formik.touched.cidb_date && formik.errors.cidb_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cidb_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Passport Validity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Passport Validatity Date'
                  {...formik.getFieldProps('passport_date')}
                />
                {formik.touched.passport_date && formik.errors.passport_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passport_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Working Permit Validatity Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Working Permit Validatity Date'
                  {...formik.getFieldProps('working_permit_date')}
                />
                {formik.touched.working_permit_date && formik.errors.working_permit_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.working_permit_date}</div>
                  </div>
                )}
              </div>
            </div>

              <div className='bg-light rounded p-5 ms-n5'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Company Name in working permit different as Employer's Details</label>

                  <div className='col-lg-8 fv-row'>
                    <div className='d-flex align-items-center mt-3'>
                      <label className='form-check form-check-inline form-check-solid me-5'>
                        <input
                          className='form-check-input'
                          name='company_status'
                          type='radio'
                          value='1' // Value for 'Yes'
                          onChange={(e) => formik.setFieldValue('company_status', Number(e.target.value))}
                        />
                        <span className='fw-bold ps-2 fs-6'>Yes</span>
                      </label>

                      <label className='form-check form-check-inline form-check-solid'>
                        <input
                          className='form-check-input'
                          name='company_status'
                          type='radio'
                          value='0' // Value for 'No'
                          onChange={(e) => formik.setFieldValue('company_status', Number(e.target.value))}
                        />
                        <span className='fw-bold ps-2 fs-6'>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Company Undertaking Letter</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='file'
                      className='form-control form-control-lg form-control-solid'
                      {...formik.getFieldProps('company_letter')}
                    />
                    {formik.touched.company_letter && formik.errors.company_letter && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_letter}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Company Name</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company Name'
                      {...formik.getFieldProps('company_name_diff')}
                    />
                    {formik.touched.company_name_diff && formik.errors.company_name_diff && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company_name_diff}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            <div className='pt-5'>
                <div className='bg-light rounded p-5 ms-n5'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Current Residential Address</label>

                    <div className='col-lg-8 fv-row'>
                      <div className='d-flex align-items-center mt-3'>
                        <label className='form-check form-check-inline form-check-solid me-5'>
                          <input
                            className='form-check-input'
                            name='company_work_different'
                            type='radio'
                            value='1' // Value for 'CLQ'
                            onChange={(e) => formik.setFieldValue('clq_status', Number(e.target.value))}
                          />
                          <span className='fw-bold ps-2 fs-6'>CLQ</span>
                        </label>

                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='company_work_different'
                            type='radio'
                            value='0' // Value for 'Non CLQ'
                            onChange={(e) => formik.setFieldValue('clq_status', Number(e.target.value))}
                          />
                          <span className='fw-bold ps-2 fs-6'>Non CLQ</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Residential Address</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Residential Address'
                        {...formik.getFieldProps('address')}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className='card-body border-top p-9'>
            <div className='card-title mb-10'>
              <h3 className='fw-bolder m-0 '>Part B - Emergency Details (Spouse / Next of Kin)</h3>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('emergency_first_name')}
                    />
                    {formik.touched.emergency_first_name && formik.errors.emergency_first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.emergency_first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('emergency_last_name')}
                    />
                    {formik.touched.emergency_last_name && formik.errors.emergency_last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.emergency_last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Relationship</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Relationship'
                  {...formik.getFieldProps('relationship')}
                />
                {formik.touched.relationship && formik.errors.relationship && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.relationship}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Residential Address</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Residential Address'
                  {...formik.getFieldProps('emergency_address')}
                />
                {formik.touched.emergency_address && formik.errors.emergency_address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Mobile No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Mobile No'
                  {...formik.getFieldProps('emergency_phone_no')}
                />
                {formik.touched.emergency_phone_no && formik.errors.emergency_phone_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_phone_no}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Home Phone No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Home Phone No'
                  {...formik.getFieldProps('emergency_home_phone_no')}
                />
                {formik.touched.emergency_home_phone_no && formik.errors.emergency_home_phone_no && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emergency_home_phone_no}</div>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ViewPersonnelWrapper}
